import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'




import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'









import implementation from '../images/services/new/outreach-services-implementation.jpg'
import monitoring from '../images/services/new/outreach-services-centralized-monitoring.jpg'
import consulting from '../images/services/new/outreach-services-consulting.jpg'

const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | Digital Outreach Services | Services">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SERVICES</h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                    
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={consulting}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>Digital Outreach Consulting</h3>
                                                <p>We do consulting to assess the feasibility of digital outreach transformation for effective capitalization and ROI (Return On Investment). This can also include an implementation of a POC (Proof Of Concept) where necessary.
                                                    <br/>
                                                    We closely work with our Global Technology Partners in articulating and implementing POCs and solutions that are pragmatic, effective, and tailor-made.
                                                </p>
                                               
                                              
                                
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={implementation}>
                                  
                                            <div className="hidden-works-item-text">
                                            <h3>Solution Implementation, Management, and Support</h3>
                                                <p>We also provide digital outreach services to design, implement, manage, and support outreach solutions for effective business growth and expansion.

                                                </p>
                                               
                                              
                                                              
                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={monitoring}>
                                  
                                    <div className="hidden-works-item-text">
                                            <h3>Centralized and Unified Tracking and Action Platform</h3>
                                                <p>Our centralized and unified tracking and action platform Sentinel <a class="codered" href="https://sentinel.pragicts.com" target="_blank">(https://sentinel.pragicts.com)</a> provides the canvas to integrate the IoT and Sensors to be centrally tracked and monitored with custom alert parameters.
                                             
                                                </p>
                                               
                                              
                                                                         
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                            
                                            </div>
                                        
                                    </div>
                               
                                  
                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>
                    <section data-scrollax-parent="true" id="KPIs">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>SENSORS / IoTs </div>
            <div className="container">
                <div className="section-title fl-wrap">
                   
                    <h2>SENSORS /<span> IoTs</span></h2>
          
                </div>
                <div className="process-wrap fl-wrap">
                    <ul>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-airflow-sensors-icon"></i>
                            </div>
                            <h4>Airflow Sensors</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>Monitor the windspeed of environments and equipment</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">01.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-access-icon"></i>
                            </div>
                            <h4>Access Sensors</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>Installation on doors, windows, etc., sensor controls the status of the door, and window: opened, closed.  A chain connection is possible.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">02.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-general-observability-platform-icon"></i>
                            </div>
                            <h4>Oxygen, Humidity, Temperature, Pressure, Smoke and Fire Sensors</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>To monitor both indoor and outdoor environments.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">03.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-motion-sensors-icon"></i>
                            </div>
                            <h4>Motion Sensors</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>PIR (Passive Infrared) Radar Motion Sensors</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">04.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-soil-vibration-sensors-icon"></i>
                            </div>
                            <h4>Vibration Sensors</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Installation on walls, windows, etc.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">05.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-leakage-sensors-icon"></i>
                            </div>
                            <h4>Leakage Sensors monitored through moisture level through a cable attachment</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">06.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-current-sensors-icon"></i>
                            </div>
                            <h4>AC / DC Voltage Monitoring Sensors</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">07.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-level-sensors-icon"></i>
                            </div>
                            <h4>Level Meters Sensors</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">08.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-people-counting-sensors-icon"></i>
                            </div>
                            <h4>People Counting Sensors and Equipment</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">09.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-smart-office-icon"></i>
                            </div>
                            <h4>Smart Office</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">10.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-smart-restroom-icon"></i>
                            </div>
                            <h4>Smart Restroom</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">11.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-quality-sensors-icon"></i>
                            </div>
                            <h4>Indoor Air Quality</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">12.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-parking-traffic-sensors-icon"></i>
                            </div>
                            <h4>Intelligent Traffic and Parking Management</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">13.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-soil-sensors-icon"></i>
                            </div>
                            <h4>Soil Environment Monitoring Monitoring soil temperature, humidity, pH value, electrical conductivity, and tension.</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">14.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-smart-irrigation-sensors-icon"></i>
                            </div>
                            <h4>Smart Irrigation Enable remote irrigation by controlling the on/off water and fertilization pumps and the opening/closing of water valves.</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">15.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-leakage-sensors-icon"></i>
                            </div>
                            <h4>Device Status Monitoring Monitoring the status of voltage transmitters and water pressure transmitters.</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">16.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-temp-humidity-sensors-restroom-icon"></i>
                            </div>
                            <h4>Greenhouse Temperature and Ventilation Management Sensors and Equipment</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">17.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-drone-agriculture-icon"></i>
                            </div>
                            <h4>Drones</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>Automated spraying, monitoring, surveillance,  crop estimation, and counting, and growth estimations.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">18.</span>
                        </li>
                     

                    </ul>
                </div>
            </div>
           
            <div className="sec-lines"></div>
        </section>
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
